import React from 'react';
import logo from './images/logo.png';
import './App.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faVk} from "@fortawesome/free-brands-svg-icons";
import {faTwitter} from "@fortawesome/free-brands-svg-icons";
import {faDiscord} from "@fortawesome/free-brands-svg-icons";
import {faYoutube} from "@fortawesome/free-brands-svg-icons";

function App() {
  return (
    <div className="App">
        <Container fluid className="d-flex flex-column h-100">
            <Row className="justify-content-md-center h-100">
                <Col className="d-flex align-items-center col-12" >
                    <Image fluid src={logo} className="align-self-center mx-auto App-logo"></Image>
                </Col>
                <Col className="d-flex align-items-center col-12">
                    <h1 className="text-center w-100">Under construction</h1>
                </Col>
                <Col className="d-flex col-12">
                    <Col className="d-flex mw-100 justify-content-center gap-3 align-items-center" >
                        <a href="https://vk.com/red_miso_studios"><FontAwesomeIcon icon={faVk} size="2x" color="red" /></a>
                        <a href="https://discords.com/servers/redmisostudios"><FontAwesomeIcon icon={faDiscord} size="2x" color="red" /></a>
                        <a href="https://twitter.com/HardLine_Team"><FontAwesomeIcon icon={faTwitter} size="2x" color="red" /></a>
                        <a href="https://www.youtube.com/@RedMisoStudios"><FontAwesomeIcon icon={faYoutube} size="2x" color="red" /></a>
                    </Col>
                </Col>
            </Row>
        </Container>
    </div>
  );
}

export default App;
